import { useMsal } from '@azure/msal-react';
import React, { useEffect, useRef, useState } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { FaUserAlt } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { possibleEnvs } from '../../constants/possibleEnvs';
import {
  disabledBreadCrumbs,
  translationBreadCrumbs,
} from '../../constants/translation/breadCrumbs';
import { cn } from '../../utils/cn';

const envName = import.meta.env.VITE_ENV_NAME as
  | 'local'
  | 'production'
  | 'homolog';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const currentUrl = location.pathname + location.search + location.hash;
  let currentLink = '';

  const crumbs = location.pathname
    .split('/')
    .filter((crumb) => crumb !== '')
    .map((crumb) => {
      currentLink += '/' + crumb;
      const crumbUpperCased = crumb;

      const crumbFormatted = translationBreadCrumbs[crumbUpperCased];

      if (!crumbFormatted) return null;
      const redirectLink = disabledBreadCrumbs.includes(crumbUpperCased)
        ? currentUrl
        : currentLink;

      return (
        <Link
          key={currentLink}
          to={redirectLink}
          className={`${
            location.pathname === currentLink
              ? 'text-primary'
              : 'text-[#707070]'
          } text-sm font-medium after:content-['_>'] last:after:hidden`}
        >
          {crumbFormatted}
        </Link>
      );
    })
    .filter((crumb) => crumb !== null);

  const { instance } = useMsal();

  const handleLogout = async () => {
    try {
      instance.logoutPopup();
    } catch (e) {
      toast.error('Erro ao sair');
    }
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = ({ target }: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(target as Node)
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [dropdownRef]);

  return (
    <header
      className="
        flex
        h-9 items-center justify-between bg-transparent
      "
    >
      <div className="flex items-center gap-3">
        <div className="flex gap-1">{crumbs}</div>
        {envName !== 'production' && possibleEnvs[envName] && (
          <div
            className={cn(
              'ml-4 flex items-center rounded-md px-6 py-1 text-sm text-white',
              possibleEnvs[envName].color,
            )}
          >
            {possibleEnvs[envName].name}
          </div>
        )}
      </div>

      <div className="relative flex" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen((old) => !old)}
          className="fixed right-5 top-2 flex h-10 w-10 items-center justify-center rounded-full border-[1.5px] border-[#DFE0EB] bg-white p-2"
        >
          {/* <img
          src="/profilepic.jpg"
          className="w-10 h-10 rounded-full max-w-none"
        /> */}
          <FaUserAlt className="text-[#a6a6a6]" />
        </button>

        <div
          className={`
            absolute 
            right-0 
            top-[2.4rem] z-50 
            flex flex-col 
            rounded-lg 
            bg-white
            p-2 shadow-light transition-all before:absolute before:-top-1 before:right-3 before:-z-10 before:h-4 before:w-4
            before:rotate-45
            before:bg-white
            before:content-['']
            ${
              isOpen
                ? 'visible translate-y-5 opacity-100'
                : 'invisible translate-y-0 opacity-0'
            }
          `}
        >
          <button
            className="group flex items-center gap-6 rounded-md px-3 py-2 transition-all hover:bg-btn-light"
            onClick={handleLogout}
          >
            <BiLogOut
              className="flex-none text-[#a6a6a6] transition-all group-hover:text-[#292929]"
              size={16}
            />
            <span className="text-gray text-sm transition-all group-hover:text-[#292929]">
              Sair
            </span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
