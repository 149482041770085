import { FC, forwardRef, InputHTMLAttributes, MouseEvent } from 'react';

import { cn } from '../../utils/cn';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  required?: boolean;
  containerClassname?: string;
  labelClassName?: string;
  onMouseEnter?: (event: MouseEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      required,
      containerClassname,
      onMouseEnter,
      labelClassName = '',
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        className={cn(
          'flex w-full flex-col gap-1 align-middle',
          containerClassname,
        )}
        onMouseEnter={onMouseEnter}
      >
        {label ? (
          <label
            className={cn('text-sm font-medium text-primary', labelClassName)}
          >
            {label}
            {required && <span className="text-red">*</span>}
          </label>
        ) : null}

        <input
          {...rest}
          ref={ref}
          required={required}
          className={cn(
            'h-8 w-full rounded-md border border-[#D8D8D8] px-3 font-graphie text-[12px] font-normal text-[#4A4A4A] active:border-[#cbcbcb]  disabled:bg-gray-50',
            `${rest.className ?? ''}`,
          )}
        />
      </div>
    );
  },
);

Input.displayName = 'Input';
export default Input;
