/* eslint-disable no-unsafe-optional-chaining */
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from '@/components/atoms/Button';
import ControlledSimpleSelect from '@/components/atoms/ControlledSimpleSelect';
import Line from '@/components/atoms/Line';
import NoPermissionText from '@/components/atoms/NoPermissionText';
import { SingleDatePicker } from '@/components/atoms/SingleDatePicker';
import Spinner from '@/components/atoms/Spinner';
import ExpandableSubtitle from '@/components/molecules/ExpandableSubtitle';
import SearchInput from '@/components/molecules/SearchInput';
import TrainingPlanningWrapper from '@/components/molecules/TrainingPlanning';
import { TrainingPlanningDayCell } from '@/components/molecules/TrainingPlanningDayCell';
import ExtraordinaryAllocationModal from '@/components/organisms/ExtraordinaryAllocationModal';
import InfiniteTable from '@/components/organisms/InfiniteTable';
import Table from '@/components/organisms/Table';
import ThirdPartyAllocationModal from '@/components/organisms/ThirdPartyAllocationModal';
import {
  employeeColumns,
  previewEmployeeColumns,
  previewExternalEmployeeColumns,
} from '@/constants/tableColumns/TrainingPlanningTableColumns';
import {
  Modality,
  OnlineTrainingType,
  SelectOption,
} from '@/constants/TrainingConstants';
import { Availability, ExternalLocation } from '@/constants/TrainingPlanning';
import { TrainingTypeEnum } from '@/constants/trainingTypeOptions';
import api from '@/services/apiSgft';
import { TrainingFile } from '@/types/TrainingFile';
import {
  TrainingDay,
  TrainingPlanning,
  TrainingPlanningFormGroup,
  TrainingPlanningSubmitData,
} from '@/types/TrainingPlanning';
import {
  TrainingPlanningAvaibilityEmployee,
  TrainingPlanningEmployee,
} from '@/types/TrainingPlanningEmployee';
import { TrainingPlanningFilters } from '@/types/TrainingPlanningFilters';
import { TrainingPlanningForm } from '@/types/TrainingPlanningForm';
import { TrainingsForTrainingPlanning } from '@/types/TrainingsForTrainingPlanning';
import { removeTimeZone, removeTimeZoneFromString } from '@/utils/formatDate';
import { userCanSeeTrainingControl } from '@/utils/handleSavePermissions';

import EmptyIcon from '../../assets/icons/empty.svg';
import LightTooltip from './../../components/atoms/LightTooltip';

const TrainingPlanningPage = () => {
  const [date, setDate] = useState('');
  const [registrationDeadLine, setRegistrationDeadLine] = useState('');

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isExtraordinaryModalOpen, setIsExtraordinaryModalOpen] =
    useState(false);
  const [
    isExternalExtraordinaryModalOpen,
    setIsExternalExtraordinaryModalOpen,
  ] = useState(false);
  const [searchedEmployeeName, setSearchedEmployeeName] = useState('');
  const [searchedExternalEmployeeName, setSearchedExternalEmployeeName] =
    useState('');
  const [searchedPreviewEmployeeName, setSearchedPreviewEmployeeName] =
    useState('');
  const [
    searchedPreviewExternalEmployeeName,
    setSearchedPreviewExternalEmployeeName,
  ] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [modalities, setModalities] = useState<SelectOption[]>([]);
  const [workStations, setWorkStations] = useState<SelectOption[]>([]);
  const { trainingFileId, trainingPlanningId } = useParams();
  const navigate = useNavigate();
  const [isLoadingEmployees, setIsLoadingEmployees] = useState(false);

  const [trainingPlanning, setTrainingPlanning] =
    useState<TrainingPlanningForm>({
      selectedEmployee: [],
      isAllEmployeeSelected: false,
      trainingDate: null,
      extraordinaryAllocationId: '',
    });
  const {
    selectedEmployee,
    isAllEmployeeSelected,
    trainingDate,
    extraordinaryAllocationId,
  } = trainingPlanning;
  const fetchTranningPlanning = async (): Promise<TrainingPlanning> => {
    if (!trainingPlanningId) return {} as TrainingPlanning;
    try {
      const response = await api.get(
        `training-plannings/${trainingPlanningId}`,
      );

      if (trainingPlanningId) {
        setModalities(response.data.modality);
      } else {
        const modalities =
          trainingFile?.supplier.modalities[response.data.trainingId as number];
        if (modalities) {
          const formattedModalities = modalities.map((modality: Modality) => ({
            label: modality,
            value: modality,
          }));
          setModalities(formattedModalities);
        }
      }

      setWorkStations([
        ...response.data.workStations,
        {
          label: ExternalLocation,
          value: -1,
        },
      ]);
      methods.setValue('training', response.data.training[0].value);
      methods.setValue('modality', response.data.modality[0].value);
      methods.setValue('onlineTrainingType', response.data.onlineTrainingType);
      methods.setValue('partModality', response.data.partModality);
      methods.setValue('asyncLocation', response.data.asyncLocation);
      methods.setValue('numberOfDays', response.data.numberOfDays);
      methods.setValue('trainingDays', response.data.trainingDays);
      setStartTime(response.data.startTime);
      setEndTime(response.data.endTime);
      setDate(removeTimeZoneFromString(response.data.startDate));
      methods.setValue(
        'registrationDeadLine',
        removeTimeZoneFromString(response.data.registrationDeadLine),
      );

      setTrainingPlanning({
        selectedEmployee: [
          ...response.data.employees.map((el: TrainingPlanningEmployee) => {
            return {
              employee: el,
              extraordinaryAllocationMotive: el.extraordinaryAllocationMotive,
              isExtraordinaryAllocation: el.isExtraordinaryAllocation,
            };
          }),
        ],
        isAllEmployeeSelected: false,
        trainingDate: response.data.trainingDate
          ? removeTimeZone(new Date(response.data.trainingDate))
          : null,
        extraordinaryAllocationId: '',
      });
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data: trainingPlaningData } = useQuery(
    ['training-planning'],
    fetchTranningPlanning,
    {
      enabled: !!trainingPlanningId,
      retry: false,
    },
  );

  const fetchTrainingFile = async (): Promise<TrainingFile> => {
    try {
      const response = await api.get(`training-files/${trainingFileId}`);

      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data: trainingFile } = useQuery(
    ['training-file', trainingFileId],
    fetchTrainingFile,
    {
      retry: false,
    },
  );

  const fetchTranningPlanningSelectedEmployees =
    async (): Promise<TrainingPlanning> => {
      try {
        const response = await api.get(
          `training-plannings/${trainingPlanningId}`,
          {
            params: {
              selectedDate: date,
              startTime,
              endTime,
            },
          },
        );
        const responseIds = response.data.employees.map(
          (el: TrainingPlanningEmployee) => el.id,
        );
        const updatedSelectedEmployee = [
          ...selectedEmployee.filter(
            (el) => !responseIds.includes(el.employee.id),
          ),
          ...response.data.employees.map((el: TrainingPlanningEmployee) => {
            return {
              employee: el,
              extraordinaryAllocationMotive: el.extraordinaryAllocationMotive,
              isExtraordinaryAllocation: el.isExtraordinaryAllocation,
            };
          }),
        ];
        setTrainingPlanning({
          selectedEmployee: updatedSelectedEmployee,
          isAllEmployeeSelected: isAllEmployeeSelected || false,
          trainingDate: response.data.trainingDate
            ? removeTimeZone(new Date(response.data.trainingDate))
            : null,
          extraordinaryAllocationId: '',
        });
        return response.data;
      } catch (e) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: 'error',
        });
        throw e;
      }
    };

  useQuery(
    ['training-planning-selected-employees', date, startTime, endTime],
    fetchTranningPlanningSelectedEmployees,
    {
      enabled: false,
      retry: false,
    },
  );

  const methods = useForm<TrainingPlanningFormGroup>();
  const { watch, register } = methods;
  const training = watch('training');
  const modality = watch('modality');
  const numberOfDays = watch('numberOfDays');
  const management = watch('management');
  const coordination = watch('coordination');
  const workStation = watch('workStation');
  const role = watch('role');
  const state = watch('state');
  const status = watch('situation');
  const rmStatus = watch('RMSituation');
  const realization = watch('realization');
  const expirationStatus = watch('expirationStatus');
  const onlineTrainingType = watch('onlineTrainingType');
  const partModality = watch('partModality');
  const trainingDays = watch('trainingDays');
  const onlineTraining =
    modality === Modality.Online ||
    (modality === Modality.Semipresencial && partModality === Modality.Online);
  const readOnlyTraining = modality === Modality.SomenteLeitura;

  const asyncTraining =
    (onlineTraining || readOnlyTraining) &&
    onlineTrainingType === OnlineTrainingType.Async;
  useEffect(() => {
    if (modality === Modality.Presencial) {
      methods.setValue('partModality', Modality.Presencial);
    } else if (modality === Modality.Online) {
      methods.setValue('partModality', Modality.Online);
    } else if (modality === Modality.SomenteLeitura) {
      methods.setValue('partModality', Modality.SomenteLeitura);
      methods.setValue('onlineTrainingType', OnlineTrainingType.Async);
    } else if (modality === Modality.Semipresencial && !partModality) {
      methods.setValue('onlineTrainingType', undefined);
    }
  }, [modality]);

  useEffect(() => {
    const currentTrainings = methods.getValues('trainingDays') || [];
    const updatedTrainings = [...currentTrainings];

    if ((numberOfDays || 0) < currentTrainings.length) {
      // Remove extra trainings
      updatedTrainings.splice(numberOfDays || 0);
    }
    methods.setValue('trainingDays', updatedTrainings);
  }, [numberOfDays]);
  const canSave =
    selectedEmployee.length !== 0 &&
    (!!training || trainingPlanningId) &&
    !!modality &&
    (!!trainingDays?.length || asyncTraining) &&
    selectedEmployee.every(
      (el) =>
        el.employee.availabilityByDay.every(
          (item: { availability: { availability: Availability } }) =>
            item.availability.availability === Availability.Disponivel,
        ) || el.isExtraordinaryAllocation,
    );

  const fetchTrainings = async (): Promise<TrainingsForTrainingPlanning> => {
    try {
      const response = await api.get(
        `training-files/${trainingFileId}/trainings`,
      );
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data: trainingData } = useQuery(['trainingsData'], fetchTrainings, {
    enabled: trainingPlanningId === undefined,
    retry: false,
  });

  const fetchFilters = async (): Promise<TrainingPlanningFilters> => {
    try {
      const response = await api.get(`training-plannings/filters`);
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data: filtersData } = useQuery(['filtersData'], fetchFilters, {
    retry: false,
  });

  const fetchTrainingModalities = useCallback(
    async (trainingId: string) => {
      try {
        const response = await api.get(
          `training-files/${trainingFileId}/trainings/${trainingId}/modalities`,
        );
        if (trainingPlanningId) {
          setModalities(response.data.modalitiesEdit);
        } else {
          setModalities(response.data.modalitiesCreate);
        }

        setWorkStations([
          ...response.data.workStations,
          {
            label: ExternalLocation,
            value: -1,
          },
        ]);
      } catch (e) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: 'error',
        });
        throw e;
      }
    },
    [trainingFileId, setModalities, setWorkStations, trainingPlanningId],
  );

  useEffect(() => {
    if (training && trainingPlanningId === undefined) {
      fetchTrainingModalities(training);
    }
  }, [fetchTrainingModalities, training, trainingPlanningId]);

  const fetchEmployees = async ({
    pageParam = 1,
  }): Promise<{
    employees: TrainingPlanningEmployee[];
    totalResults: number;
    nextPage: number | null;
  }> => {
    try {
      if (pageParam === 1) setIsLoadingEmployees(true);
      const responseSelected =
        selectedEmployee.length > 0
          ? await api.get(
              `training-plannings/trainings/${training}/employees`,
              {
                params: {
                  size: 1000,
                  trainingDays: trainingDays || [],
                  trainingPlanningId,
                  management,
                  modality,
                  coordination,
                  workStation,
                  role,
                  state,
                  status,
                  rmStatus,
                  realization,
                  expirationStatus,
                  partModality,
                  onlineTrainingType,
                  employeeIds: selectedEmployee.map((el) => el.employee.id),
                },
              },
            )
          : { data: { employees: [] } };
      const response = await api.get(
        `training-plannings/trainings/${training}/employees`,
        {
          params: {
            size: 10,
            page: pageParam,
            employee: searchedEmployeeName,
            trainingPlanningId,
            trainingFileId,
            trainingDays: trainingDays || [],
            modality,
            management,
            coordination,
            workStation,
            role,
            state,
            status,
            rmStatus,
            realization,
            expirationStatus,
            partModality,
            onlineTrainingType,
            isThirdParty: false,
          },
        },
      );
      setTrainingPlanning((prevState) => {
        const updatedSelectedEmployee = responseSelected.data.employees.map(
          (el: TrainingPlanningAvaibilityEmployee) => {
            const existingEmployee = prevState.selectedEmployee.find(
              (prevEl) => prevEl.employee.id === el.id,
            );
            const allAvailable = el.availabilityByDay.every(
              (day) =>
                day.availability.availability === Availability.Disponivel,
            );

            return {
              employee: el,
              extraordinaryAllocationMotive: allAvailable
                ? ''
                : existingEmployee
                  ? existingEmployee.extraordinaryAllocationMotive
                  : el.extraordinaryAllocationMotive,
              isExtraordinaryAllocation: allAvailable
                ? false
                : existingEmployee
                  ? existingEmployee.isExtraordinaryAllocation
                  : el.isExtraordinaryAllocation,
            };
          },
        );

        const remainingEmployees = prevState.selectedEmployee.filter(
          (prevEl) =>
            !responseSelected.data.employees.some(
              (el: TrainingPlanningAvaibilityEmployee) =>
                el.id === prevEl.employee.id,
            ),
        );

        return {
          ...prevState,
          selectedEmployee: [...updatedSelectedEmployee, ...remainingEmployees],
        };
      });
      setIsLoadingEmployees(false);
      return response.data;
    } catch (e) {
      setIsLoadingEmployees(false);
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const fetchExternalEmployees = async ({
    pageParam = 1,
  }): Promise<{
    employees: TrainingPlanningEmployee[];
    totalResults: number;
    nextPage: number | null;
  }> => {
    try {
      const response = await api.get(
        `training-plannings/trainings/${training}/employees`,
        {
          params: {
            size: 10,
            page: pageParam,
            employee: searchedExternalEmployeeName,
            trainingDays: trainingDays || [],
            trainingPlanningId,
            modality,
            management,
            coordination,
            workStation,
            role,
            state,
            status,
            rmStatus,
            realization,
            expirationStatus,
            isThirdParty: true,
          },
        },
      );
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const areTrainingPlanningDayCellCorrect = () => {
    if (!trainingDays?.length) {
      return false;
    }
    for (const day of trainingDays) {
      if (!day.date || !day.startTime || !day.endTime) {
        return false;
      }
    }
    return true;
  };

  const areTherePlanningsOnTheSameDay = () => {
    const seenDates = new Set();
    for (const day of trainingDays ?? []) {
      if (seenDates.has(day.date)) {
        return true;
      }
      seenDates.add(day.date);
    }
    return false;
  };

  const filterByInformationToRefresh = (days?: TrainingDay[]) => {
    if (!days) return [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return days.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ onlineLocation, locationDetail, roomName, ...rest }) => rest,
    );
  };
  const filteredTrainingDays = filterByInformationToRefresh(trainingDays);
  const {
    data: employees,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    [
      'training-planning-employee',
      training,
      modality,
      filteredTrainingDays,
      searchedEmployeeName,
      management,
      coordination,
      workStation,
      role,
      state,
      status,
      rmStatus,
      realization,
      expirationStatus,
      partModality,
      onlineTrainingType,
      false,
    ],
    fetchEmployees,
    {
      retry: false,
      enabled:
        !!searchedEmployeeName ||
        (!!modality &&
          !!training &&
          (areTrainingPlanningDayCellCorrect() ||
            partModality === Modality.SomenteLeitura ||
            (partModality === Modality.Online &&
              onlineTrainingType === OnlineTrainingType.Async))),
      getNextPageParam: (actualPage) => {
        return actualPage.nextPage;
      },
    },
  );

  const { data: externalEmployees } = useInfiniteQuery(
    [
      'training-planning-employee',
      training,
      modality,
      date,
      searchedEmployeeName,
      management,
      coordination,
      workStation,
      role,
      state,
      status,
      rmStatus,
      realization,
      expirationStatus,
      true,
    ],
    fetchExternalEmployees,
    {
      retry: false,
      enabled:
        !!searchedExternalEmployeeName ||
        (!!date && !!modality && !!training && !!startTime && !!endTime),
      getNextPageParam: (actualPage) => {
        return actualPage.nextPage;
      },
    },
  );

  const onClose = () => {
    setTrainingPlanning({
      extraordinaryAllocationId: '',
      isAllEmployeeSelected: false,
      selectedEmployee: [],
      trainingDate: null,
    });
    navigate('/home/ficheiros');
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!areTrainingPlanningDayCellCorrect) {
      toast.error('Os dias de treinamento não foram preenchidos corretamente', {
        theme: 'colored',
        toastId: 'error',
      });
      return;
    }

    if (!areTherePlanningsOnTheSameDay) {
      toast.error(
        'O mesmo planejmanento não pode ocorrer mais de uma vez em um dia',
        {
          theme: 'colored',
          toastId: 'error',
        },
      );
      return;
    }

    const combinedSelectedEmployees = [...selectedEmployee];

    if (isAllEmployeeSelected) {
      const availableEmployeesToAdd =
        employeesData
          ?.filter(
            (employee) =>
              employee?.availability?.availability === Availability.Disponivel,
          )
          .map((employee) => ({
            employee,
            extraordinaryAllocationMotive: '',
            isExtraordinaryAllocation: false,
            isThirdParty: employee.isThirdParty,
          })) || [];

      combinedSelectedEmployees.push(...availableEmployeesToAdd);
    }
    const formattedSelectedEmployees = combinedSelectedEmployees.map(
      (selected) => ({
        id: selected.employee.id,
        extraordinaryAllocationMotive: selected.extraordinaryAllocationMotive,
        isExtraordinaryAllocation: selected.isExtraordinaryAllocation,
        realization: selected.employee.realization,
        isThirdParty: selected.employee.isThirdParty,
        statusByDay: selected.employee.statusByDay,
      }),
    );

    let requestBody: TrainingPlanningSubmitData = {
      selectedEmployees: formattedSelectedEmployees,
      trainingPlanningDays: trainingDays as TrainingDay[],
      partModality,
      onlineTrainingType,
      asyncLocation: methods.getValues('asyncLocation'),
      numberOfDays: Number(numberOfDays) || 0,
      registrationDeadLine: methods.getValues('registrationDeadLine'),
      modality,
    };
    if (!trainingPlanningId) {
      requestBody = {
        ...requestBody,
        trainingFileId,
        trainingId: methods.getValues('training'),
      };
    }
    setIsSubmiting(true);
    try {
      trainingPlanningId
        ? await api.put(
            `/training-plannings/${trainingPlanningId}`,
            requestBody,
          )
        : await api.post('/training-plannings', requestBody);

      toast.success('Planejamento de turma cadastrado com sucesso!', {
        theme: 'colored',
        toastId: 'success',
      });
      if (!trainingPlanningId) {
        methods.reset();
        setTrainingPlanning({
          selectedEmployee: [],
          isAllEmployeeSelected: false,
          trainingDate: null,
          extraordinaryAllocationId: '',
        });
      }
      const url = trainingPlanningId
        ? `/home/ficheiros/detalhamento/${trainingFileId}/?turma=${trainingPlanningId}`
        : `/home/ficheiros/detalhamento/${trainingFileId}`;
      navigate(url);
    } catch (error: any) {
      if (trainingPlanningId)
        toast.error(
          `Erro ao atualizar turma! - ${error.response.data.message}`,
          {
            theme: 'colored',
            toastId: 'error',
          },
        );
      else
        toast.error('Erro ao cadastrar turma!', {
          theme: 'colored',
          toastId: 'error',
        });
    } finally {
      setIsSubmiting(false);
    }
  };

  const onAddExtraordinaryAllocation = () => {
    if (
      (areTrainingPlanningDayCellCorrect() || asyncTraining) &&
      !!training &&
      !!modality
    ) {
      setTrainingPlanning({
        selectedEmployee,
        isAllEmployeeSelected,
        trainingDate,
        extraordinaryAllocationId: '',
      });
      setIsExtraordinaryModalOpen(true);
    }
  };

  const onAddExternalExtraordinaryAllocation = () => {
    if (
      (areTrainingPlanningDayCellCorrect() || asyncTraining) &&
      !!training &&
      !!modality
    ) {
      setTrainingPlanning({
        selectedEmployee,
        isAllEmployeeSelected,
        trainingDate,
        extraordinaryAllocationId: '',
      });
      setIsExternalExtraordinaryModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsExtraordinaryModalOpen(false);
  };

  const handleCloseExternalEmployeeModal = () => {
    setIsExternalExtraordinaryModalOpen(false);
  };

  const handleInputChange = (name: string) => {
    setSearchedEmployeeName(name);
  };

  const handleExternalInputChange = (name: string) => {
    setSearchedExternalEmployeeName(name);
  };

  const handlePreviewInputChange = (name: string) => {
    setSearchedPreviewEmployeeName(name);
  };

  const handleExternalPreviewInputChange = (name: string) => {
    setSearchedPreviewExternalEmployeeName(name);
  };

  const employeesData = useMemo(
    () => employees?.pages.flatMap((page) => page.employees) ?? [],
    [employees],
  );

  const thirdPartyEmployeesData = useMemo(
    () => externalEmployees?.pages.flatMap((page) => page.employees) ?? [],
    [externalEmployees],
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        name === 'employeeName' &&
        type === 'change' &&
        typeof value?.employeeName === 'string'
      ) {
        handleInputChange(value?.employeeName);
      } else if (
        name === 'previewEmployeeName' &&
        type === 'change' &&
        typeof value?.previewEmployeeName === 'string'
      ) {
        handlePreviewInputChange(value?.previewEmployeeName);
      } else if (
        name === 'externalEmployeeName' &&
        type === 'change' &&
        typeof value?.externalEmployeeName === 'string'
      ) {
        handleExternalInputChange(value?.externalEmployeeName);
      } else if (
        name === 'previewExternalEmployeeName' &&
        type === 'change' &&
        typeof value?.previewExternalEmployeeName === 'string'
      ) {
        handleExternalPreviewInputChange(value?.previewExternalEmployeeName);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (isAllEmployeeSelected) {
      setTrainingPlanning({
        selectedEmployee: [
          ...selectedEmployee,
          ...employeesData
            ?.filter(
              (el) =>
                el?.availability?.availability === Availability.Disponivel,
            )
            .map((el) => ({
              employee: el,
              extraordinaryAllocationMotive: '',
              isExtraordinaryAllocation: false,
              isThirdParty: el.isThirdParty,
            })),
        ],
        isAllEmployeeSelected: true,
        trainingDate,
        extraordinaryAllocationId: '',
      });
    }
  }, [
    employees,
    employeesData,
    isAllEmployeeSelected,
    selectedEmployee,
    setTrainingPlanning,
    trainingDate,
  ]);

  useEffect(() => {
    setTrainingPlanning({
      selectedEmployee,
      isAllEmployeeSelected,
      trainingDate: null,
      extraordinaryAllocationId,
    });
  }, [
    extraordinaryAllocationId,
    isAllEmployeeSelected,
    selectedEmployee,
    setTrainingPlanning,
  ]);

  const filteredPreviewData = useMemo(() => {
    let filteredEmployees = [];
    if (isAllEmployeeSelected) {
      filteredEmployees = [
        ...selectedEmployee.filter((el) => el.isExtraordinaryAllocation),
        ...employeesData
          ?.filter(
            (el) => el?.availability?.availability === Availability.Disponivel,
          )
          .map((el) => ({
            employee: el,
            extraordinaryAllocationMotive: '',
            isExtraordinaryAllocation: false,
            isThirdParty: el.isThirdParty,
          })),
      ];
    } else {
      filteredEmployees = selectedEmployee.filter((el) => !el.isThirdParty);
    }

    if (!searchedPreviewEmployeeName?.trim()) {
      return filteredEmployees.map((el) => el.employee);
    } else {
      return filteredEmployees
        .filter(
          (employee) =>
            employee.employee.name
              .toLowerCase()
              .includes(searchedPreviewEmployeeName?.toLowerCase()) ||
            employee.employee.employeeNumber
              .toLowerCase()
              .includes(searchedPreviewEmployeeName?.toLowerCase()),
        )
        .map((el) => el.employee);
    }
  }, [
    searchedPreviewEmployeeName,
    selectedEmployee,
    employeesData,
    isAllEmployeeSelected,
  ]);

  const filteredExternalPreviewData = useMemo(() => {
    let filteredEmployees = [];
    if (isAllEmployeeSelected) {
      filteredEmployees = [
        ...selectedEmployee.filter((el) => el.isExtraordinaryAllocation),
        ...thirdPartyEmployeesData
          ?.filter(
            (el) => el?.availability?.availability === Availability.Disponivel,
          )
          .map((el) => ({
            employee: el,
            extraordinaryAllocationMotive: '',
            isExtraordinaryAllocation: false,
            isThirdParty: el.isThirdParty,
          })),
      ];
    } else {
      filteredEmployees = selectedEmployee.filter(
        (el) => el.employee.isThirdParty,
      );
    }
    if (!searchedPreviewExternalEmployeeName?.trim()) {
      return filteredEmployees.map((el) => el.employee);
    } else {
      return filteredEmployees
        .filter(
          (employee) =>
            employee.employee.name
              .toLowerCase()
              .includes(searchedPreviewExternalEmployeeName?.toLowerCase()) ||
            employee.employee.employeeNumber
              .toLowerCase()
              .includes(searchedPreviewExternalEmployeeName?.toLowerCase()) ||
            employee.employee.company
              ?.toLowerCase()
              .includes(searchedPreviewExternalEmployeeName?.toLowerCase()),
        )
        .map((el) => el.employee);
    }
  }, [
    searchedPreviewExternalEmployeeName,
    selectedEmployee,
    thirdPartyEmployeesData,
    isAllEmployeeSelected,
  ]);

  const clearFilters = () => {
    methods.setValue('management', []);
    methods.setValue('coordination', []);
    methods.setValue('role', []);
    methods.setValue('state', []);
    methods.setValue('situation', []);
    methods.setValue('RMSituation', []);
    methods.setValue('realization', []);
    methods.setValue('expirationStatus', []);
  };

  const trainingOptions = trainingPlanningId
    ? trainingPlaningData?.training
    : trainingData?.trainings;

  const startDate = trainingPlanningId
    ? trainingPlaningData?.startDate
    : trainingData?.startDate;

  const endDate = trainingPlanningId
    ? trainingPlaningData?.endDate
    : trainingData?.endDate;

  if (!userCanSeeTrainingControl()) return <NoPermissionText />;
  return (
    <div className="relative flex w-full flex-col items-start pt-4">
      <TrainingPlanningWrapper isError={false} isLoading={false}>
        <FormProvider {...methods}>
          <form
            className="space-between flex h-[90%] flex-col px-6"
            onSubmit={onSubmit}
          >
            <ExpandableSubtitle subtitle={'Treinamento'}>
              <div className="pb-2">
                <div className="flex w-full flex-wrap items-center justify-start gap-4 pb-6">
                  <ControlledSimpleSelect
                    title={'Treinamento'}
                    readOnly={trainingPlanningId !== undefined}
                    control={methods.control}
                    name={`training`}
                    width="100%"
                    containerClassName="w-96"
                    values={trainingOptions}
                  />
                  <ControlledSimpleSelect
                    title={'Modalidade'}
                    required
                    control={methods.control}
                    name={`modality`}
                    width="100%"
                    containerClassName="w-40"
                    values={modalities}
                  />
                  {modality === Modality.Semipresencial && (
                    <ControlledSimpleSelect
                      title="Etapa do Treinamento"
                      required
                      control={methods.control}
                      name={`partModality`}
                      width="100%"
                      containerClassName="w-40"
                      values={[
                        {
                          label: Modality.Online,
                          value: Modality.Online,
                        },
                        {
                          label: Modality.Presencial,
                          value: Modality.Presencial,
                        },
                      ]}
                    />
                  )}
                  {onlineTraining && (
                    <ControlledSimpleSelect
                      title={'Realização'}
                      required
                      control={methods.control}
                      name={`onlineTrainingType`}
                      width="100%"
                      containerClassName="w-1/4"
                      values={[
                        {
                          label: OnlineTrainingType.Sync,
                          value: OnlineTrainingType.Sync,
                        },
                        {
                          label: OnlineTrainingType.Async,
                          value: OnlineTrainingType.Async,
                        },
                      ]}
                    />
                  )}
                  {!asyncTraining &&
                    modality &&
                    partModality &&
                    (partModality === Modality.Presencial ||
                      (onlineTrainingType &&
                        onlineTrainingType !== OnlineTrainingType.Async)) && (
                      <div className="flex  flex-col">
                        <span className="text-[12px] text-gray-600">
                          Quantidades de dias
                        </span>
                        <input
                          {...register('numberOfDays')}
                          className="h-8 w-32 rounded-sm px-2 text-[12px] text-gray-700 ring-1 ring-gray-300"
                          type="number"
                          defaultValue={0}
                          min={1}
                          max={10}
                        />
                      </div>
                    )}
                </div>
              </div>
            </ExpandableSubtitle>
            {partModality && (
              <ExpandableSubtitle
                subtitle={`Etapa ${onlineTraining ? 'Online' : 'Presencial'}`}
              >
                <div className="flex flex-col gap-2 pb-2">
                  {!asyncTraining && (
                    <div>
                      <div className="flex w-full flex-col items-start justify-center gap-4 pb-6">
                        <div className="flex w-full items-center justify-between">
                          <div className="flex w-full flex-col gap-4">
                            {Array.from({ length: numberOfDays || 0 }).map(
                              (_, index) => (
                                <TrainingPlanningDayCell
                                  key={`trainingPlanningDayCell-${index}`}
                                  methods={methods}
                                  startDate={startDate}
                                  endDate={endDate}
                                  trainingFileId={trainingFileId as string}
                                  index={index}
                                  workStations={workStations}
                                />
                              ),
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {asyncTraining && (
                    <div className="flex  flex-col gap-2">
                      <SingleDatePicker
                        form={methods}
                        className="ml-1 h-8 w-64 py-0"
                        label="Data de Liberação"
                        required
                        minDate={
                          startDate
                            ? removeTimeZone(new Date(startDate))
                            : new Date()
                        }
                        maxDate={
                          endDate
                            ? removeTimeZone(new Date(endDate))
                            : new Date()
                        }
                        {...register('registrationDeadLine')}
                      />
                      <Line />

                      <div className="flex w-full flex-col items-start justify-between gap-4 pb-6">
                        <div className="flex w-full flex-col">
                          <span className="text-[12px] text-gray-600">
                            Local de realização
                          </span>
                          <input
                            {...register('asyncLocation')}
                            required={false}
                            className="h-8 rounded-sm px-2 text-[12px] text-gray-700 ring-1 ring-gray-300"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </ExpandableSubtitle>
            )}
            <ExpandableSubtitle subtitle={'Filtros'}>
              <div className="flex w-full flex-wrap items-center justify-start gap-3 pb-3">
                <ControlledSimpleSelect
                  title={'Gerência RM'}
                  control={methods.control}
                  name={`management`}
                  width="100%"
                  containerClassName={'w-full max-w-36'}
                  values={filtersData?.management?.map((el) => {
                    return {
                      value: el.value,
                      label: el.label,
                    };
                  })}
                  multiple
                />
                <ControlledSimpleSelect
                  title={'Coordenação RM'}
                  control={methods.control}
                  name={`coordination`}
                  width="100%"
                  containerClassName={'w-full max-w-36'}
                  values={filtersData?.coordination?.map((el) => {
                    return {
                      value: el.value,
                      label: el.label,
                    };
                  })}
                  multiple
                />
                <ControlledSimpleSelect
                  title={'Local de trabalho'}
                  control={methods.control}
                  name={`workStation`}
                  width="100%"
                  containerClassName={'w-full max-w-36'}
                  values={filtersData?.workStation?.map((el) => {
                    return {
                      value: el.value,
                      label: el.label,
                    };
                  })}
                  multiple
                />
                <ControlledSimpleSelect
                  title={'Função'}
                  control={methods.control}
                  name={`role`}
                  width="100%"
                  containerClassName={'w-full max-w-36'}
                  values={filtersData?.roles?.map((el) => {
                    return {
                      value: el.value,
                      label: el.label,
                    };
                  })}
                  multiple
                />
                <ControlledSimpleSelect
                  title={'Estado de moradia'}
                  control={methods.control}
                  name={`state`}
                  width="100%"
                  containerClassName={'w-full max-w-36'}
                  values={filtersData?.state?.map((el) => {
                    return {
                      value: el.value,
                      label: el.label,
                    };
                  })}
                  multiple
                />
                {!(
                  onlineTrainingType === OnlineTrainingType.Async &&
                  (modality === Modality.Online ||
                    (modality === Modality.Semipresencial &&
                      partModality === Modality.Online))
                ) && (
                  <ControlledSimpleSelect
                    title={'Situação'}
                    control={methods.control}
                    name={`situation`}
                    width="100%"
                    disabled={trainingDays?.some((el) => !el.date)}
                    containerClassName={'w-full max-w-36'}
                    values={filtersData?.status?.map((el) => {
                      return {
                        value: el.value,
                        label: el.label,
                      };
                    })}
                    multiple
                  />
                )}
                <ControlledSimpleSelect
                  title={'Situação RM'}
                  control={methods.control}
                  name={`RMSituation`}
                  width="100%"
                  containerClassName={'w-full max-w-36'}
                  values={filtersData?.rmStatus?.map((el) => {
                    return {
                      value: el.value,
                      label: el.label,
                    };
                  })}
                  multiple
                />
                <ControlledSimpleSelect
                  title={'Realização'}
                  control={methods.control}
                  name={`realization`}
                  width="100%"
                  containerClassName={'w-full max-w-36'}
                  values={filtersData?.fulfillment?.map((el) => {
                    return {
                      value: el.value,
                      label: el.label,
                    };
                  })}
                  multiple
                />
                <ControlledSimpleSelect
                  title={'Situação de Vencimento'}
                  control={methods.control}
                  name={`expirationStatus`}
                  width="100%"
                  containerClassName={'w-full max-w-36'}
                  values={filtersData?.expirationStatus?.map((el) => {
                    return {
                      value: el.value,
                      label: el.label,
                    };
                  })}
                  multiple
                />
              </div>

              <div className="flex justify-end">
                <Button
                  className="rounded bg-transparent px-2 py-1 text-xs text-black underline hover:bg-transparent"
                  onClick={clearFilters}
                  type="button"
                >
                  Limpar Filtros
                </Button>
              </div>
            </ExpandableSubtitle>
            <ExpandableSubtitle subtitle={'Colaboradores'}>
              <div className="flex flex-col items-start gap-2">
                <div className="w-[24rem] px-4 py-4">
                  {!!numberOfDays &&
                  !!modality &&
                  !!training &&
                  !!areTrainingPlanningDayCellCorrect ? (
                    <SearchInput
                      placeholder="Pesquisar por colaborador"
                      {...methods.register('employeeName')}
                    />
                  ) : (
                    <div />
                  )}
                </div>
              </div>
              {isLoadingEmployees ? (
                <Spinner />
              ) : employeesData.length > 0 ? (
                <div className="flex max-h-96 flex-col items-start gap-2">
                  <InfiniteTable
                    hasNextPage={hasNextPage}
                    data={employeesData}
                    columns={employeeColumns(
                      false,
                      trainingPlanning,
                      setTrainingPlanning,
                    )}
                    fetchNextPage={fetchNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    tableContainerClassName="min-h-32"
                    columnVisibility={{
                      availability: !asyncTraining,
                      trainings: !asyncTraining,
                      nearestLanding: !asyncTraining,
                      nearestBoarding: !asyncTraining,
                      statusInDay: !asyncTraining,
                    }}
                  />
                </div>
              ) : (
                <div className="flex h-[98%] w-full flex-col items-center justify-center rounded-md bg-white">
                  <img src={EmptyIcon} className="w-40" />
                  <div className="w-full text-center text-sm">
                    <p>Nenhum colaborador cadastrado com esses parâmetros</p>
                    <span className="font-semibold text-blue-800">
                      Altere os valores dos filtros ou preencha os campos de
                      data e hora!
                    </span>
                  </div>
                </div>
              )}
            </ExpandableSubtitle>
            <ExpandableSubtitle
              subtitle={`Prévia de turma (${filteredPreviewData.filter((el) => !el.isThirdParty).length})`}
            >
              {isLoadingEmployees ? (
                <div className="flex h-96 items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                selectedEmployee.filter((el) => !el.isThirdParty).length >
                  0 && (
                  <div className="flex max-h-96 flex-col items-start gap-2">
                    <div className="w-1/2 px-4">
                      <SearchInput
                        placeholder="Pesquisar por colaborador"
                        {...methods.register('previewEmployeeName')}
                      />
                    </div>
                    <Table
                      columns={previewEmployeeColumns(
                        trainingPlanning,
                        setTrainingPlanning,
                      )}
                      data={filteredPreviewData.filter(
                        (el) => el !== 'all' && !el.isThirdParty,
                      )}
                      containerClassname="w-full overflow-auto"
                      tdHeaderClassname="text-center"
                      columnVisibility={{
                        availability: !asyncTraining,
                        trainings: !asyncTraining,
                        nearestLanding: !asyncTraining,
                        nearestBoarding: !asyncTraining,
                        statusInDay: !asyncTraining,
                      }}
                    />
                  </div>
                )
              )}
            </ExpandableSubtitle>
            {filteredExternalPreviewData.filter((el) => el.isThirdParty)
              .length > 0 && (
              <ExpandableSubtitle
                subtitle={`Prévia dos terceiros da turma (${filteredExternalPreviewData.filter((el) => el.isThirdParty).length})`}
                containerClassName="mt-4"
              >
                {isLoadingEmployees ? (
                  <div className="flex h-96 items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <div className="flex max-h-96 flex-col items-start gap-2">
                    <div className="w-1/2 px-4">
                      <SearchInput
                        placeholder="Pesquisar por terceiro"
                        {...methods.register('previewExternalEmployeeName')}
                      />
                    </div>
                    <Table
                      columns={previewExternalEmployeeColumns(
                        trainingPlanning,
                        setTrainingPlanning,
                      )}
                      data={filteredExternalPreviewData.filter(
                        (el) => el !== 'all' && el.isThirdParty,
                      )}
                      containerClassname="w-full overflow-auto"
                      tdHeaderClassname="text-center"
                      columnVisibility={{
                        trainings: !asyncTraining,
                      }}
                    />
                  </div>
                )}
              </ExpandableSubtitle>
            )}
            <div className="flex-center flex w-full justify-end">
              <LightTooltip
                title="Adicionar colaborador terceiro"
                arrow
                placement="top-start"
              >
                <button
                  type="button"
                  className="mx-6 mt-2 flex items-center justify-end text-[12px] font-extrabold text-[#193CB9] underline"
                  onClick={onAddExternalExtraordinaryAllocation}
                >
                  + Alocação de Terceiros
                </button>
              </LightTooltip>
              {trainingFile?.trainingType !== TrainingTypeEnum.DEVELOPMENT && (
                <LightTooltip
                  title="Alocação de pessoas que não necessitam desse treinamento originalmente ou que estão indisponível na data escolhida"
                  arrow
                  placement="top-start"
                >
                  <button
                    type="button"
                    className="mx-6 mt-2 flex items-center justify-end text-[12px] font-extrabold text-[#193CB9] underline disabled:opacity-50"
                    onClick={onAddExtraordinaryAllocation}
                  >
                    + Alocação extraordinária
                  </button>
                </LightTooltip>
              )}
            </div>

            <div className="mb-4 mt-8 flex w-full justify-end gap-5">
              <Button
                type="button"
                className="min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                onClick={() => onClose()}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="min-w-36"
                isLoading={isSubmiting}
                disabled={!canSave}
              >
                Concluir
              </Button>
            </div>
          </form>
        </FormProvider>
        <ExtraordinaryAllocationModal
          isOpen={isExtraordinaryModalOpen}
          onClose={handleCloseModal}
          trainingDays={trainingDays ?? []}
          modality={modality}
          training={training}
          trainingPlanningId={trainingPlanningId}
          onlineTrainingType={onlineTrainingType}
          setTrainingPlanning={setTrainingPlanning}
          trainingPlanning={trainingPlanning}
        />
        {isExternalExtraordinaryModalOpen && (
          <ThirdPartyAllocationModal
            isOpen={isExternalExtraordinaryModalOpen}
            onClose={handleCloseExternalEmployeeModal}
            trainingDays={trainingDays ?? []}
            modality={modality}
            training={training}
            trainingPlanningId={trainingPlanningId}
            onlineTrainingType={onlineTrainingType}
            setTrainingPlanning={setTrainingPlanning}
            trainingPlanning={trainingPlanning}
          />
        )}
      </TrainingPlanningWrapper>
    </div>
  );
};

export default TrainingPlanningPage;
