import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useDebounce } from 'use-debounce';

import { Button } from '@/components/atoms/Button';
import { JobTitleLine } from '@/components/atoms/JobTitleLine';
import Spinner from '@/components/atoms/Spinner';
import { JobTitleDownloadButton } from '@/components/molecules/JobTitleDownloadButton';
import SideFilter from '@/components/molecules/SideFilter';
import ViewScheduleWrapper from '@/components/molecules/ViewScheduleWrapper';
import PrintJobTitlePDF from '@/components/organisms/PrintJobTitlePDF';
import { filters } from '@/constants/filters/JobTitleFilter';
import api from '@/services/apiSgft';
import { jobTitleAtom } from '@/state/JobTitle.atom';
import { GroupedJobTitle, JobTitle } from '@/types/JobTitle';

import EmptyIcon from '../../assets/icons/empty.svg';
import JobTitleAccordion from './JobTitleAccordion/JobTitleAccordion';

const ViewJobTitles: React.FC = () => {
  const filterValues = useRecoilValue(jobTitleAtom);
  const [filteredData, setFilteredData] = useState(filterValues);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermDebounced] = useDebounce(searchTerm, 500);

  const [selectedRolesId, setSelectedRolesId] = useState<number[]>([]);
  const [currentPrintRoleId, setCurrentPrintRoleId] = useState<number | null>(
    null,
  );
  const observerRef = useRef<HTMLDivElement | null>(null); // Ref para o observer do último elemento

  const printRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchTerm(e.target.value); // Atualiza o estado com o valor do input
  };

  const fetchJobTitles = async ({
    pageParam = 1,
  }): Promise<{
    data: GroupedJobTitle[];
    nextPage: number | undefined;
    hasMore: boolean;
  }> => {
    try {
      const response = await api.get('/job-title/grouped', {
        params: {
          title: searchTerm,
          seniorityLevels: filteredData?.seniorityLevels,
          companies: filteredData?.companies,
          boards: filteredData?.boards,
          managements: filteredData?.managements,
          workStations: filteredData?.workStations,
          // requirementType: filteredData?.requirementTypes,
          // educationalRequirements: filteredData?.educationalRequirements,
          // professionalRequirements: filteredData?.professionalRequirements,
          // otherRequirements: filteredData?.otherRequirements,
          page: pageParam,
        },
      });

      const objResults = response.data;
      const hasMore = objResults.length > 0;

      return {
        data: objResults,
        nextPage: hasMore ? pageParam + 1 : undefined,
        hasMore,
      };
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ['jobTitle', filteredData, searchTermDebounced],
      fetchJobTitles,
      {
        getNextPageParam: (lastPage) => lastPage.nextPage,
      },
    );
  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [hasNextPage, isFetchingNextPage, fetchNextPage],
  );

  // Configurando o observer
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '100px',
      threshold: 1.0,
    });
    if (observerRef.current) observer.observe(observerRef.current);
    return () => observer.disconnect();
  }, [observerCallback]);

  const groupedJobTitles = data?.pages.flatMap((page) => page.data) || [];
  const allJobTitles = groupedJobTitles.flatMap((group) => group.jobTitles);
  const handleCreateJobTitle = () => {
    const urlRedmine = import.meta.env.VITE_REDMINE_URL;

    const path = `${urlRedmine}/issues/new`;
    window.open(path);
  };

  const handlePrint = useReactToPrint({
    content: () =>
      currentPrintRoleId !== null
        ? printRefs.current[currentPrintRoleId]
        : null,
    documentTitle:
      currentPrintRoleId !== null
        ? allJobTitles?.find((jobTitle) => jobTitle.id === currentPrintRoleId)
            ?.generalInformationJobTitle.name
          ? `Descrição de Cargo - ${allJobTitles.find((jobTitle) => jobTitle.id === currentPrintRoleId)?.generalInformationJobTitle.name.toUpperCase()} ${
              allJobTitles?.find(
                (jobTitle) => jobTitle.id === currentPrintRoleId,
              )?.seniorityLevel.name !== 'N/A'
                ? allJobTitles
                    ?.find((jobTitle) => jobTitle.id === currentPrintRoleId)
                    ?.seniorityLevel.name.toUpperCase()
                : ''
            }`
          : 'Descrição de Cargo'
        : 'Descrição de Cargo',
    onAfterPrint: () => {
      const nextRoleId = selectedRolesId.find(
        (id) => id !== currentPrintRoleId,
      );
      if (nextRoleId) {
        setSelectedRolesId((prevIds) =>
          prevIds.filter((id) => id !== currentPrintRoleId),
        );
        setCurrentPrintRoleId(nextRoleId);
      } else {
        setSelectedRolesId((prevIds) =>
          prevIds.filter((id) => id !== currentPrintRoleId),
        );
        setCurrentPrintRoleId(null);
      }
    },
  });

  useEffect(() => {
    if (currentPrintRoleId !== null) {
      handlePrint();
    }
  }, [currentPrintRoleId, handlePrint]);

  const handleApplyFilter = (isReseting: boolean) => {
    if (isReseting)
      setFilteredData(() => ({
        titles: '',
        seniorityLevels: [],
        companies: [],
        boards: [],
        managements: [],
        workStations: [],
        // requirementTypes: [],
        // educationalRequirements: [],
        // professionalRequirements: [],
        // otherRequirements: [],
      }));
    else {
      setFilteredData(() => ({
        ...filterValues,
      }));
    }
  };

  return (
    <div className="relative flex h-[calc(100%-3rem)] max-h-screen w-full flex-col items-start">
      <div className="absolute -top-10 right-20 flex items-center">
        <div className="flex h-[2rem] w-[12rem] items-center justify-between rounded-lg p-4 text-[13px]">
          <Button
            className="mx-5 h-7 rounded-[1rem] text-xs font-medium"
            onClick={() => handleCreateJobTitle()}
          >
            Solicitar novo cargo
          </Button>
        </div>
        <JobTitleDownloadButton
          searchTerm={searchTerm}
          filteredData={filteredData}
        />
      </div>
      <SideFilter
        refetchOnChange
        filters={filters}
        atom={jobTitleAtom}
        applyChanges={handleApplyFilter}
      />
      <div className="mt-5 w-[93vw] px-5">
        <div className="my-2 mb-1 flex w-full justify-end bg-white p-2">
          <div className="flex h-[2rem] w-[20rem] items-center justify-between rounded-lg bg-background p-4 text-[13px] ">
            <input
              className="h-[2rem] w-[20rem] rounded-lg bg-background p-4 text-[13px] focus:outline-none "
              type="text"
              placeholder="Pesquisar por cargo"
              value={searchTerm}
              onChange={handleOnchange}
            />
            <IoIosSearch
              className="cursor-auto"
              color="#193db8"
              size={'18px'}
            />
          </div>
        </div>
      </div>
      <ViewScheduleWrapper isError={false} isLoading={isLoading}>
        <div className="custom-scrollbar h-[80vh] w-full gap-4 overflow-auto rounded-md px-5">
          {groupedJobTitles && groupedJobTitles.length > 0 ? (
            <div className="flex h-full flex-col items-start gap-[10px]">
              {groupedJobTitles.map((groupedJobTitle) => (
                <JobTitleAccordion
                  title={groupedJobTitle.title}
                  key={groupedJobTitle.id}
                >
                  {groupedJobTitle.jobTitles.map((jobTitle: JobTitle) => {
                    return (
                      <div key={jobTitle.id} className="relative w-full">
                        <JobTitleLine jobTitle={jobTitle} />
                        <div className="hidden">
                          <PrintJobTitlePDF
                            ref={(el) => {
                              printRefs.current[jobTitle.id] = el;
                            }}
                            jobTitle={jobTitle}
                          />
                        </div>
                      </div>
                    );
                  })}
                </JobTitleAccordion>
              ))}

              <div ref={observerRef} className="block h-2 w-full ">
                &nbsp;
              </div>
              {isFetchingNextPage && (
                <div className="flex w-full justify-center p-4">
                  <Spinner />
                </div>
              )}
            </div>
          ) : (
            <div className="flex h-[98%] w-full flex-col items-center justify-center rounded-md bg-white">
              <img src={EmptyIcon} className="w-40" />
              <div className="w-full text-center text-sm">
                <p>Nenhum cargo cadastrado com esses parâmetros</p>
                <span className="font-semibold text-blue-800">
                  Cadastre um ou altere os valores dos filtros para visualizar
                  os cargos!
                </span>
              </div>
            </div>
          )}
        </div>
      </ViewScheduleWrapper>
    </div>
  );
};

export default ViewJobTitles;
