import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import FileUploadComponent from '@/components/atoms/FileUpload';
import TableCellDate from '@/components/atoms/TableCellDate';
import TableCellText from '@/components/atoms/TableCellText';
import TableCellTrainingStatus from '@/components/atoms/TableCellTrainingStatus';
import TableData from '@/components/atoms/TableData';
import { ViewFiles } from '@/components/atoms/ViewFiles';
import api from '@/services/apiSgft';
import { EvidenceFile } from '@/types/EvidenceFile';
import { TrainingControlHistory } from '@/types/TrainingControlHistory';
import { removeTimeZone } from '@/utils/formatDate';

import { StatusTrainingControl } from '../TrainingConstants';

export const columns: () => Array<ColumnDef<TrainingControlHistory>> = () => [
  {
    accessorFn: (row) => row.training.name,
    header: 'TREINAMENTO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      const [open, setOpen] = useState(false);
      const options = [];
      const shouldDisable =
        props.row.original.closed &&
        props.row.original.completionStatus ===
          StatusTrainingControl.NaoAplicavel;

      if (
        !props.row.original.closed &&
        props.row.original.completionStatus ===
          StatusTrainingControl.NaoAplicavel
      ) {
        options.push({
          name: 'Reaplicar Treinamento',
          onClick: () => handleReapplyTraining(),
          disabled: false,
        });
      } else if (
        props.row.original.completionStatus !==
        StatusTrainingControl.NaoAplicavel
      ) {
        options.push({
          name: 'Editar Informações',
          onClick: () => setOpen(true),
          disabled: false,
        });
      }

      async function handleReapplyTraining() {
        try {
          await api.get(
            `training-control/training-control-history/${props.row.original.id}/reapply-training/`,
          );
          toast.success('O treinamento foi reaplicado com sucesso!', {
            theme: 'colored',
          });
        } catch (e: any) {
          const errorMessage = 'Não foi possível reaplicar o treinamento';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        } finally {
          queryClient.invalidateQueries({ queryKey: ['employee-trainings'] });
        }
      }
      return (
        <div className="flex w-[14rem] items-center justify-start">
          <TableData
            tableValue={(props.getValue() as string)?.toUpperCase() || ''}
            className="w-44 overflow-hidden text-ellipsis whitespace-nowrap px-3 text-start"
            title={(props.getValue() as string)?.toUpperCase() || ''}
          />
          {/*
            TODO: Ajustar lógica do back para atualizar controle de treinamento
          <SimpleMenu options={options} disabled={shouldDisable} />
          <EditTrainingModal
            isOpen={open}
            onClose={() => setOpen(false)}
            id={props.row.original.id}
            row={props.row.original}
          /> */}
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.training.type,
    header: 'TIPO DE TREINAMENTO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-32">
        <TableCellText
          text={props.getValue<string>().toUpperCase()}
          width="8rem"
        />
      </div>
    ),
  },
  {
    accessorKey: 'registerDate',
    header: 'DATA DE INSCRIÇÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      return (
        <TableCellDate
          date={
            (props.getValue() as string) !== '-'
              ? removeTimeZone(new Date(props.getValue() as string))
              : undefined
          }
        />
      );
    },
  },
  {
    accessorKey: 'completionDate',
    header: 'DATA DE CONCLUSÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      return (
        <TableCellDate
          date={
            (props.getValue() as string) !== '-'
              ? removeTimeZone(new Date(props.getValue() as string))
              : undefined
          }
        />
      );
    },
  },
  {
    accessorKey: 'completionStatus',
    header: 'SITUAÇÃO DO TREINAMENTO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      return (
        <div className="mr-5 flex w-40">
          <TableCellTrainingStatus title={(props.getValue() as string) || ''} />
        </div>
      );
    },
  },
  {
    accessorKey: 'statusJustification',
    header: 'JUSTIFICATIVA DA SITUAÇÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      return (
        <div className="mr-5 flex w-40">
          <TableCellText
            text={(props.getValue() as string)?.toUpperCase() || ''}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'updatedAt',
    header: 'DATA DA ÚLTIMA MODIFICAÇÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-2 flex w-[11rem]">
        <TableCellDate
          date={
            props.getValue() !== '-'
              ? removeTimeZone(new Date(props.getValue() as string))
              : undefined
          }
          className="flex justify-center"
        />
      </div>
    ),
  },
  // TODO: Ajustar lógica do back para atualizar controle de treinamento
  // {
  //   accessorKey: 'modificationJustification',
  //   header: 'JUSTIFICATIVA DA MODIFICAÇÃO',
  //   meta: {
  //     headerClassName: 'text-start',
  //   },
  //   cell: (props) => (
  //     <div className="mr-5 flex w-[11rem]">
  //       <TableCellText
  //         text={(props.getValue() as string)?.toUpperCase() || ''}
  //         width="10.5rem"
  //       />
  //     </div>
  //   ),
  // },
  {
    header: 'UPLOAD DE EVIDÊNCIAS',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      const [isLoading, setIsLoading] = useState(false);
      const id = props.row.original.id;
      const employeeName = props.row.original.employee.name;
      const viewFiles = async () => {
        try {
          const response = await api.get(
            `training-control/training-control-history/${id}/evidences/`,
          );
          return response.data.map((el: File) => {
            return {
              ...el,
              employeeName,
            };
          });
        } catch (error: any) {
          toast.error(
            error?.response?.data?.message ??
              'Erro ao buscar arquivos de evidências!',
            {
              theme: 'colored',
              toastId: 'error',
            },
          );
        }
      };

      const { data: files } = useQuery<EvidenceFile[]>(
        ['get-evidence-files', id],
        viewFiles,
        {
          retry: false,
          refetchOnMount: false,
        },
      );
      async function handleFilesSelected(files: File[], id: string) {
        setIsLoading(true);
        const formData = new FormData();
        for (const file of files) {
          formData.append('files', file);
        }

        formData.append('id', id);
        try {
          await api.post(
            `training-control/training-control-history/${props.row.original.id}/upload-evidence/`,
            formData,
          );
          toast.success('Arquivo(s) foram salvos com sucesso!', {
            theme: 'colored',
          });
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível salvar o(s) arquivo(s) enviado(s)!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        } finally {
          setIsLoading(false);
          queryClient.invalidateQueries({
            queryKey: ['get-evidence-files'],
          });
          queryClient.invalidateQueries({
            queryKey: ['employee-trainings'],
          });
        }
      }
      const handleDeleteEvidence = async (evidenceId: number) => {
        try {
          await api.delete(`training-control/delete-evidence/${evidenceId}`);
          queryClient.invalidateQueries({
            queryKey: ['get-evidence-files'],
          });
          toast.success(`Evidência deletada com sucesso!`, {
            theme: 'colored',
            toastId: 'success',
          });
          queryClient.invalidateQueries({
            queryKey: ['employee-trainings'],
          });
        } catch {
          toast.error('Não foi possível deletar a evidência!', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };

      return (
        <div className="fle items-center justify-center">
          {isLoading ? (
            <div className="flex cursor-pointer flex-col items-center justify-center text-center">
              <CgSpinner
                size={20}
                color="#c1c1c1"
                className="h-full animate-spin"
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <FileUploadComponent
                onFilesSelected={(filesList: FileList | null) => {
                  const filesArray = filesList ? Array.from(filesList) : [];
                  handleFilesSelected(
                    filesArray,
                    String(props.row.original.id),
                  );
                }}
                rowId={String(props.row.original.id)}
              />
              <ViewFiles
                files={files ?? []}
                onDeleteFunction={handleDeleteEvidence}
                deleteFileDescrption={`do histórico do colaborador ${props.row.original.employee.name} não poderá ser recuperado`}
              />
            </div>
          )}
        </div>
      );
    },
  },
];
