import { MouseEvent, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import { JobTitle } from '@/types/JobTitle';
import { removeTimeZone } from '@/utils/formatDate';

import JobTitleHistoryModal from '../organisms/JobTitleHistoryModal';
import JobTitleModal from '../organisms/JobTitleModal';
import PrintJobTitlePDF from '../organisms/PrintJobTitlePDF';
import LightTooltip from './LightTooltip';
import SimpleMenu from './Menu';
import TableCellDate from './TableCellDate';

interface JobTitleLineProps {
  jobTitle: JobTitle;
}
export const JobTitleLine = ({ jobTitle }: JobTitleLineProps) => {
  const [isJobTitleModalOpen, setIsJobTitleModalOpen] = useState(false);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const [isJobTitleHistoryModalOpen, setIsJobTitleHistoryModalOpen] =
    useState(false);
  const printRef = useRef<HTMLDivElement>(null);

  const pdfName =
    jobTitle.seniorityLevel.name && jobTitle.seniorityLevel.name !== 'N/A'
      ? `Descrição de Cargo - ${jobTitle.generalInformationJobTitle.name.toUpperCase()} ${jobTitle.seniorityLevel.name.toUpperCase()}`
      : `Descrição de Cargo - ${jobTitle.generalInformationJobTitle.name.toUpperCase()}`;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${pdfName}`,
  });

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };
  const options = [
    {
      name: 'Editar Informações',
      onClick: () => {
        const urlRedmine = import.meta.env.VITE_REDMINE_URL;

        const path = `${urlRedmine}/issues/${jobTitle.generalInformationJobTitle?.issueId}`;
        window.open(path);
      },
      // disabled: !jobTitle.active,
      disabled: true,
    },
    {
      name: 'Consultar Versões',
      onClick: () => {
        setIsJobTitleHistoryModalOpen(true);
      },
      disabled: false,
    },
    {
      name: 'Exportar Descrição do Cargo',
      onClick: () => {
        handlePrint();
      },
      disabled: false,
    },
  ];

  return (
    <div className="flex w-full items-center justify-between rounded-lg bg-[#F5F7FF] px-2 py-1 transition-all duration-500 ease-in-out">
      <div className="flex w-full">
        <div className="flex w-full items-center justify-between gap-4">
          <LightTooltip
            title={`${jobTitle?.generalInformationJobTitle?.name} ${jobTitle?.seniorityLevel?.name}`}
            open={tooltipEnabled}
            onClose={() => setTooltipEnabled(false)}
            arrow
            placement="top-end"
          >
            <div
              className="cursor-pointer overflow-hidden text-ellipsis text-[14px] font-medium uppercase text-[#4a4a4a]"
              onMouseEnter={handleShouldShow}
              onClick={() => setIsJobTitleModalOpen(true)}
            >
              <p className="text-primary underline">
                {`${jobTitle?.generalInformationJobTitle?.name} ${jobTitle?.seniorityLevel?.name && jobTitle?.seniorityLevel?.name !== 'N/A' ? jobTitle?.seniorityLevel?.name : ''}`}
              </p>
            </div>
          </LightTooltip>
        </div>

        {!jobTitle.active && (
          <div className="ml-auto w-[4rem] rounded-xl bg-[#d9d9d9] text-center text-[12px] font-medium	text-[#5a5a5a]">
            INATIVO
          </div>
        )}
      </div>
      <div className="flex w-[40%] items-center justify-end text-[11px] text-[#848484]">
        <div className="font-medium">
          {' '}
          Versão: {jobTitle.generalInformationJobTitle?.version}
        </div>
        <div className="mx-2">●</div>
        <div className="mr-1">Última edição:</div>
        <div className="mr-1 font-medium">
          {' '}
          <TableCellDate
            className="text-[10px] font-normal text-[#848484]"
            date={removeTimeZone(new Date(jobTitle.updatedAt))}
          />
        </div>
        <SimpleMenu options={options} upDotsButton upDotsButtonColor="action" />
      </div>
      {isJobTitleModalOpen && (
        <JobTitleModal
          modalIsOpen={isJobTitleModalOpen}
          handleClose={() => setIsJobTitleModalOpen(false)}
          jobTitle={jobTitle}
        />
      )}

      {isJobTitleHistoryModalOpen && (
        <JobTitleHistoryModal
          jobTitle={jobTitle}
          modalIsOpen={isJobTitleHistoryModalOpen}
          handleClose={() => setIsJobTitleHistoryModalOpen(false)}
        />
      )}
      <div className="relative  hidden h-[100%] w-full flex-col">
        <PrintJobTitlePDF ref={printRef} jobTitle={jobTitle} />
      </div>
    </div>
  );
};
